<template>
  <v-container id="case-index-container" class="h-full d-flex flex-column justify-start font-inter" fluid>
    <MilestoneHeader :title="$t('milestone.title')" :actionText="$t('milestone.create')" :filter="filter" @update-filter="updateFilter" :ongoingItemCount="openItemCount" :archivedItemCount="closedItemCount" />

    <template>
      <v-container class="pa-6 elevation-1 white rounded-lg h-full" fluid>
        <template v-if="milestones.length > 0">
          <v-row class="align-center">
            <v-col cols="6" sm="6">
              <div class="d-flex flex-row justify-start align-center">
                <v-responsive class="ma-0" max-width="344">
                  <v-text-field :loading="loading" v-model="searchFilter" class="rounded-lg pa-0" prepend-inner-icon="mdi-magnify" :placeholder="$t('search_by_name')" dense filled hide-details></v-text-field>
                </v-responsive>
                <v-col flex class="d-flex justify-space-between align-center">
                  <MilestoneFilter v-if="this.filter == 'open'" @applyFilters="applyFilters"></MilestoneFilter>
                  <MilestoneClosedFilter v-if="this.filter == 'closed'" @applyFilters="appplyClosedFilters">
                  </MilestoneClosedFilter>
                </v-col>
              </div>
            </v-col>
            <v-col cols="6" sm="6" class="d-flex justify-end">
              <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
                <template v-slot:activator="{ on }">
                  <v-btn class="pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
                    <v-icon size="16px">
                      mdi-cog-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
                  <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('columns') }}</v-list-item>
                  <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
                    <v-checkbox v-model="header.isSelected" :value="header.isSelected" :true-value="true" :false-value="false" off-icon="mdi-checkbox-blank" class="ma-0 pa-0" color="blue" :size="16" hide-details>
                      <template v-slot:label>
                        <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                      </template>
                    </v-checkbox>
                  </v-list-item>
                  <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
                    <div class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd" :class="{ 'blue--text font-weight-semibold ': isColumnFilter }">
                      {{ $t('restoreDefault') }}
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="mainFilter == true && closeFilter == false && filter == 'open'">
            <v-col>
              <div class="custom-chip-container">
                <span class="d-flex align-center justify-center font-weight-bold">Results ({{ filteredMilestones.length
                  }})</span>
                &nbsp;&nbsp;
                <v-chip label v-for="({ index, filter }, idx) in filteredFilters" :key="idx" class="custom-chip-font-size" close @click:close="updateMainFilter(index)">
                  <template v-if="index === 'selectedRoles'">
                    Status: {{ filter }}
                  </template>
                  <template v-else-if="index === 'dateRange'">
                    Start date: {{ filter.start }} - {{ filter.end }}
                  </template>
                  <template v-else-if="index === 'dueDateRange'">
                    Due date: {{ filter.start }} - {{ filter.end }}
                  </template>
                  <template v-else-if="index === 'users'">
                    Progress: {{ filter[0] }} - {{ filter[1] }}%
                  </template>
                  <template v-else-if="index === 'testCases'">
                    Test Cases: {{ filter[0] }} - {{ filter[1] }}
                  </template>
                  <template v-else-if="index === 'testRuns'">
                    Test Runs: {{ filter[0] }} - {{ filter[1] }}
                  </template>
                </v-chip>
                <v-sheet @click="clearFilters()" color="gray-100" class="d-flex align-center justify-center" height="32px" rounded="lg">
                  <span class="px-4">
                    {{ $t('clearAll') }}
                  </span>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
          <v-row class="fill-height">
            <v-col cols="12" class="fill-height pt-1">
              <MilestoneTable :filteredHeaders="filteredHeaders" :filteredItems="filteredMilestones" :itemKey="itemKey" @select-item="setSelected" @archive-item="confirmOpenMilestone" @unarchive-item="unArchiveMilestone" @edit-item="editMilestone" @delete-item="showDeleteDialog" />
            </v-col>
          </v-row>

        </template>
        <template v-else>
          <v-row class="py-sm-16">
            <v-col cols="12" class="d-flex justify-center">
              <v-img :src="require('@/assets/png/milestone-empty-state.png')" alt="Table empty state" width="100%" max-width="438" />
            </v-col>
            <v-col cols="12">
              <v-flex>
                <p class="ma-0 font-weight-bold text-h6 text-sm-h5">{{ $t('milestone.empty_state.title') }}</p>
                <p class="mb-0 mt-3">{{ $t('milestone.empty_state.description_part1') }}</p>
                <p class="ma-0">
                  {{ $t('milestone.empty_state.description_part2') }}
                </p>
                <p class="ma-0">
                  {{ $t('milestone.empty_state.description_part3') }}
                </p>
              </v-flex>
              <v-flex class="mt-6">
                <v-btn dark large color="blue" class="text-capitalize font-weight-bold" :to="{ name: 'MilestoneCreate' }">
                  {{ $t('milestone.createMilestone') }} <v-icon class="ml-1" size="16">mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </template>
    <MilestoneArchiveDialog :title="$t('milestone.close_dialog.title')" :content="$t('milestone.close_dialog.content_part1')" :content_part2="$t('milestone.close_dialog.content_part2')" :btn_label="$t('milestone.close_dialog.btn_label')" :milestone_name="selectedMilestone.name" color="primary"
      v-model="showConfirmOpenDialog" @close="handleCloseClick" @handleConfirmClick="handleConfirmOpenClick('close')" />
    <MilestoneDeleteDialog :title="$t('milestone.close_delete_dialog.title')" :content="$t('milestone.close_delete_dialog.content_part1')" :btn_label="$t('milestone.close_dialog.btn_label')" :milestone_name="selectedMilestone.name" color="primary" v-model="showConfirmDeleteDialog"
      @close="handleDeleteCloseClick" @handleConfirmClick="handleConfirmDeleteClick('close')" />
  </v-container>
</template>

<script>
import MilestoneDeleteDialog from '@/components/Milestone/MilestoneDeleteDialog.vue';
import MilestoneArchiveDialog from '@/components/Milestone/MilestoneArchiveDialog.vue';
import MilestoneTable from '@/components/Milestone/MilestoneTable.vue';
import MilestoneHeader from '@/components/Milestone/MilestoneHeader.vue';
import MilestoneFilter from '@/components/Milestone/MilestoneFilter.vue';
import MilestoneClosedFilter from '@/components/Milestone/MilestoneClosedFilter.vue';
import dayjs from 'dayjs';
import { runStateMap, MilestoneTableHeader, DefaultFilter } from '@/constants/grid';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import MilestoneService from '@/services/api/milestone';
import { handleNetworkStatusError } from '@/mixins/redirect';

let makeMilestoneService;

export default {
  components: {
    MilestoneHeader,
    MilestoneTable,
    MilestoneArchiveDialog,
    MilestoneFilter,
    MilestoneClosedFilter,
    MilestoneDeleteDialog
  },
  mixins: [handleNetworkStatusError],
  data()
  {
    return {
      milestones: [],
      selectedMilestone: {},
      setSelected: [],
      selectedStatus: Object.values(runStateMap)[0],
      savingInProgress: false,
      headers: MilestoneTableHeader,
      filter: 'open',
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      expanded: [],
      itemKey: 'uid',
      searchFilter: '',
      showFormDialog: false,
      openedRow: undefined,
      loading: false,
      checked: false,
      showConfirmOpenDialog: false,
      showConfirmDeleteDialog: false,
      mainFilter: false,
      closeFilter: false,
      mainFilters: {
        selectedRoles: [],
        testRuns: [0, 100],
        testCases: [0, 100],
        users: [0, 100],
        dateRange: {
          start: "",
          end: "",
        },
        dueDateRange: {
          start: "",
          end: "",
        },
        progress: [0, 100]
      },
      closedFilters: {
        testRuns: [0, 100],
        testCases: [0, 100],
        users: [0, 100],
        dateRange: {
          start: "",
          end: "",
        },
      },
      handle: this.$route.params.handle,
      projectKey: this.$route.params.key,
    };
  },
  computed: {
    filteredHeaders()
    {
      const filtered = this.headers.filter((header) => header.isSelected);
      return filtered;
    },
    filteredMenuHeaders()
    {
      return this.headers.filter((header) => header.text != 'Actions');
    },
    isColumnFilter: {
      get()
      {
        return this.filteredHeaders.length < 7;
      },

    },
    filteredMilestones()
    {
      if (this.searchFilter && this.mainFilter == false && this.closeFilter == false) {
        const filtered = this.milestones.filter((item) => this.matchesFilter(item));
        return filtered;
      }
      else if (this.mainFilter == true && this.closeFilter == false && !this.searchFilter) {
        return this.milestones.filter(item =>
        {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.customFields.status)) {
            return false;
          }
          if (item.customFields.testRuns < this.mainFilters.testRuns[0] || item.customFields.testRuns > this.mainFilters.testRuns[1]) {
            return false;
          }
          if (item.customFields.testCases < this.mainFilters.testCases[0] || item.customFields.testCases > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.customFields.startDate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.dueDateRange.start && this.mainFilters.dueDateRange.end) {
            const itemDueDate = new Date(item.dueAt);
            const dueStartDate = new Date(this.mainFilters.dueDateRange.start);
            const dueEndDate = new Date(this.mainFilters.dueDateRange.end);
            if (itemDueDate < dueStartDate || itemDueDate > dueEndDate) {
              return false;
            }
          }
          if (item.customFields.progress < this.mainFilters.users[0] || item.customFields.progress > this.mainFilters.users[1]) {
            return false;
          }
          return true;
        });
      }
      else if (this.mainFilter == false && this.closeFilter == true && !this.searchFilter) {
        return this.milestones.filter(item =>
        {
          if (item.customFields.testRuns < this.closedFilters.testRuns[0] || item.customFields.testRuns > this.closedFilters.testRuns[1]) {
            return false;
          }
          if (item.customFields.testCases < this.closedFilters.testCases[0] || item.customFields.testCases > this.closedFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.closedFilters.dateRange.end) {
            const itemDate = new Date(item.customFields.startDate);
            const startDate = new Date(this.closedFilters.dateRange.start);
            const endDate = new Date(this.closedFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (item.customFields.progress < this.closedFilters.users[0] || item.customFields.progress > this.closedFilters.users[1]) {
            return false;
          }
          return true;
        });
      }
      else if (this.mainFilter == true && this.searchFilter) {
        const MainFiltered = this.milestones.filter(item =>
        {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.customFields.status)) {
            return false;
          }
          if (item.customFields.testRuns < this.mainFilters.testRuns[0] || item.customFields.testRuns > this.mainFilters.testRuns[1]) {
            return false;
          }
          if (item.customFields.testCases < this.mainFilters.testCases[0] || item.customFields.testCases > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.customFields.startDate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.dueDateRange.start && this.mainFilters.dueDateRange.end) {
            const itemDueDate = new Date(item.dueAt);
            const dueStartDate = new Date(this.mainFilters.dueDateRange.start);
            const dueEndDate = new Date(this.mainFilters.dueDateRange.end);
            if (itemDueDate < dueStartDate || itemDueDate > dueEndDate) {
              return false;
            }
          }
          if (item.customFields.progress < this.mainFilters.users[0] || item.customFields.progress > this.mainFilters.users[1]) {
            return false;
          }
          return true;
        });
        const filtered = MainFiltered.filter((item) => this.matchesFilter(item));
        return filtered;
      }
      return this.milestones
    },
    openItemCount()
    {
      if (!Array.isArray(this.milestones)) {
        return 0;
      }
      return this.milestones.filter((item) => item.deletedAt == null && item.customFields.runstatus === 'open').length;
    },
    closedItemCount()
    {
      if (!Array.isArray(this.milestones)) {
        return 0;
      }
      return this.milestones.filter((item) => item.deletedAt == null && item.customFields.runstatus === 'closed').length;
    },
    filteredFilters()
    {
      const filters = this.mainFilters;
      return Object.keys(filters).filter(index =>
      {
        const filter = filters[index];
        switch (index) {
          case 'selectedRoles':
            return !filter.length == 0;
          case 'dateRange':
          case 'dueDateRange':
            return filter.start && filter.end;
          case 'users':
            return !(filter[0] == 0 && filter[1] == 100)
          case 'testCases':
            return !(filter[0] == 0 && filter[1] == 100)
          case 'testRuns':
            return !(filter[0] == 0 && filter[1] == 100)
          default:
            return false;
        }
      }).map(index => ({ index, filter: filters[index] }));
    }
  },
  watch: {
    filteredMilestones(newValue)
    {
      if (newValue === "SEARCH_BACKEND") {
        this.searchRuns();
      }
    },
    headers: {
      deep: true,
      handler()
      {
        this.updateFilteredHeaders();
      }
    }
  },
  mounted()
  {
    this.getMilestones();
  },
  created()
  {
    makeMilestoneService = MilestoneService(this.$api);
  },
  methods: {
    isDefaultValue(index)
    {
      if (this.mainFilters[index] === DefaultFilter[index]) {
        return true
      }
      else {
        return false
      }
    },
    confirmOpenMilestone(item)
    {
      this.selectedMilestone = item;
      this.showConfirmOpenDialog = true;
    },
    showDeleteDialog(item)
    {
      this.selectedMilestone = item;
      this.showConfirmDeleteDialog = true;
    },
    handleCloseClick()
    {
      this.showConfirmOpenDialog = false;
      this.selectedMilestone = [];
    },
    handleDeleteCloseClick()
    {
      this.showConfirmDeleteDialog = false;
      this.selectedMilestone = [];
    },
    async handleConfirmOpenClick()
    {
      try {
        //TODO: This throws a 500 error on the backend
        await makeMilestoneService.updateMilestone(
          this.handle,
          this.projectKey,
          this.selectedMilestone.uid,
          { archived: true, }
        );
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Milestone' });
        this.handleCloseClick();
        this.getMilestones();
      } catch (error) {
        showErrorToast(this.$swal, 'archiveError', { item: 'Milestone' });
      }
    },
    async handleConfirmDeleteClick()
    {
      try {
        await makeMilestoneService.deleteMilestone(
          this.handle,
          this.projectKey,
          this.selectedMilestone.uid
        );
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Milestone' });
        this.handleDeleteCloseClick();
        this.getMilestones();
      } catch (error) {
        showErrorToast(this.$swal, 'deleteError', { item: 'Milestone' });
      }
    },
    applyFilters(filters)
    {
      this.mainFilters = filters;
      this.mainFilter = true;
      this.closeFilter = false
      this.getMilestones()
    },
    appplyClosedFilters(filters)
    {
      this.closedFilters = filters;
      this.mainFilter = false
      this.closeFilter = true;
      this.filter = 'closed'
    },
    async updateFilter(newFilter)
    {
      this.filter = newFilter;
      await this.getMilestones();
    },
    matchesFilter(item)
    {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);

      return nameMatch;
    },
    staticValues(item, obj, defultValue)
    {
      return obj[item.customFields.state] || obj[defultValue];
    },

    async getMilestones()
    {
      this.loading = true;
      try {
        const response = await makeMilestoneService.getMilestones(this.handle, this.projectKey);
        this.milestones = response.data.milestones || [];
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        this.milestones = []; // Reset to empty array in case of error
      } finally {
        this.loading = false;
      }
    },
    getColor(priority)
    {
      switch (priority) {
        case "Past due":
          return "font-weight-bold red--text text--lighten-1"
        case "Blocked":
          return "font-weight-bold orange--text text--lighten-1"
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Upcoming":
          return "font-weight-bold blue--text text--lighten-1"
      }
    },
    convertToLocal(timestamp)
    {
      return dayjs(timestamp).format('ddd, MMM DD hh:mm A');
    },
    determineType(test)
    {
      return test.source === 'pinata' ? 'Exploratory' : 'Manual';
    },
    updateFilteredHeaders()
    {
      const filtered = this.headers.filter((header) => header.isSelected);

    },
    handleColumnReset()
    {
      this.headers.forEach((header) => { header.isSelected = true });
      this.updateFilteredHeaders();
    },
    editMilestone(item)
    {
      this.selectedMilestone = item;
      this.$router.push({
        name: 'MilestoneEdit',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          id: item.uid,
        },
      });
    },
    unArchiveMilestone(item)
    {
      this.selectedMilestone = item;
      this.selectedMilestone.customFields.runstatus = 'open'
      showSuccessToast(this.$swal, 'unarchiveSuccess', { item: 'Milestone' });
    },
    updateMainFilter(index)
    {
      this.mainFilters[index] = DefaultFilter[index]
    },
    clearFilters()
    {
      this.mainFilter = false
      this.mainFilters = DefaultFilter
    },
  },
};
</script>
<style scoped>
.h-full {
  height: 100%;
}

.custom-runtable {
  color: #344054 !important;
}

.custom-color-0c111d {
  color: #0C111D !important;
}

.custom-color-d0d5dd {
  color: #D0D5DD !important;
}

.custom-menu-item {
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  align-items: center;
}

.custom-text-12 {
  font-size: 12px;
}

.custom-text-14 {
  font-size: 14px;
}

.custom-font-size {
  font-size: 18px;
}

.custom-chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.custom-chip-font-size {
  font-size: 16px;
}

.custom-result-title {
  font-size: 20px;
}
</style>
